import { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import dayjs from 'dayjs'
import type { NextPage } from 'next'
import { useRouter } from 'next/router'

import {
  ChecklistByMonthQuery,
  useChecklistByMonthLazyQuery,
  useMeQuery,
} from '../../graphql/api'

const ChatTemplate = dynamic(
  () => import('../components/templates/ChatTemplate'),
  { ssr: false },
)

const Home: NextPage = () => {
  const { data: meData } = useMeQuery()
  const [loggedDate] = useState<Date>(dayjs().toDate())
  const [checklist, setChecklist] =
    useState<ChecklistByMonthQuery['checklistByMonth']>()
  const [getChecklist, { data: checklistData }] = useChecklistByMonthLazyQuery()

  const router = useRouter()
  // ワコール版対応
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_FOR_WACOAL === 'true') router.push('/myPage')
  }, [])

  // meDataがあればデータを取得してくる
  useEffect(() => {
    if (meData?.me.id) {
      getChecklist({
        variables: {
          where: {
            userId: meData.me.id,
            loggedAt: loggedDate,
          },
        },
      })
    }
  }, [meData, loggedDate])

  // checklistDataが取得できればcheckList内に
  useEffect(() => {
    if (checklistData) {
      setChecklist(checklistData.checklistByMonth)
    }
  }, [checklistData])

  return (
    <div>
      <Head>
        <title>チャット | YOMOGI+</title>
      </Head>
      {meData && checklist && (
        <ChatTemplate pageType="chat" meData={meData} checklist={checklist} />
      )}
    </div>
  )
}

export default Home
